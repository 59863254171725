<template>
  <el-scrollbar class="template-wrapper" :native="false" v-loading="loading" element-loading-text="拼命加载中..." element-loading-spinner="el-icon-loading">
    <div class="steps-wrapper">
      <div class="step-item" :class="{ current: [1, 2].includes(stepActive) }">
        <div class="step-line"></div>
        <div class="step-text">1</div>
        <div class="step-title">创建运营模板</div>
      </div>
      <div class="step-item" :class="{ current: [2].includes(stepActive) }">
        <div class="step-line"></div>
        <div class="step-text">2</div>
        <div class="step-title">创建成功</div>
      </div>
    </div>
    <div class="create-content" v-if="stepActive === 1">
      <el-form :model="templateData" :rules="templateRules" ref="templateData" label-width="120px">
        <el-form-item label="数据模板名称" prop="template_name">
          <el-input v-model.trim="templateData.template_name" placeholder="请输入数据模板名称" style="width: 380px"></el-input>
        </el-form-item>
        <el-form-item label="选择数据分析" prop="data_manage_id">
          <el-select placeholder="请选择数据分析" v-model.number="templateData.data_manage_id" style="width: 380px">
            <el-option v-for="item in dataAnalysisList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择分析模块">
          <el-select placeholder="请选择分析模块" multiple v-model="analysisModule" style="width: 380px" @change="selectAnalysisModule">
            <el-option-group v-for="group in analysisModuleList" :key="group.value" :label="group.label">
              <el-option v-for="item in group.options" :key="item.value" :label="item.label" :value="item"> </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="display: flex; align-items: center" v-show="analysisModule.length > 0">
        <div style="width: 4px; height: 20px; background-color: #0200d8; margin-right: 5px; border-radius: 10px"></div>
        <div class="main-title-text">客户数据</div>
      </div>
      <div v-for="(item, index) in moduleData" class="module-data">
        <div style="margin: 10px 20px; font-weight: 800">{{ typeReturnName(item.module_type) }}</div>
        <el-form label-width="120px" :inline="true">
          <el-form-item label="题目" required>
            <el-input v-model.trim="item.question" style="width: 490px"></el-input>
          </el-form-item>
          <el-form-item label="数据诊断分值" required>
            <!-- <el-input v-model.trim="item.diagnose_score" style="width: 180px"></el-input> -->
            <el-input-number v-model="item.diagnose_score" :min="1" :max="100" :precision="0" :controls="false" style="width: 180px" @change="changeTotalScore(item, 'diagnose')"></el-input-number>
          </el-form-item>
          <el-form-item label="优化建议分值" required>
            <!-- <el-input v-model.trim="item.optimize_score" style="width: 180px"></el-input> -->
            <el-input-number v-model="item.optimize_score" :min="1" :max="100" :precision="0" :controls="false" style="width: 180px" @change="changeTotalScore(item, 'optimize')"></el-input-number>
          </el-form-item>
        </el-form>
        <div style="margin-bottom: 24px">
          <div style="margin-bottom: 16px">
            <span style="font-size: 16px; margin-right: 14px">数据诊断评分标准</span>
            <el-button type="primary" plain size="small" @click="addScore(item, 1)">添加评分标准</el-button>
          </div>
          <el-table :data="item.diagnose_standard" style="width: 100%" :header-cell-style="{ background: '#f0f2f5', color: '#333' }" :height="195">
            <el-table-column type="index" label="序号"> </el-table-column>
            <el-table-column prop="content" label="考核内容"> </el-table-column>
            <el-table-column prop="mark" label="评分标准"> </el-table-column>
            <el-table-column prop="percentage" label="分值占比(%)"> </el-table-column>
            <el-table-column prop="score" label="分值"> </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-link type="primary" :underline="false" style="margin-right: 12px" @click="onEdit(scope.row, 1, scope.$index, item)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="onDel(scope.$index, item.diagnose_standard)">删除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-bottom: 24px">
          <div style="margin-bottom: 16px">
            <span style="font-size: 16px; margin-right: 14px">优化建议评分标准</span>
            <el-button type="primary" plain size="small" @click="addScore(item, 2)">添加评分标准</el-button>
          </div>
          <el-table :data="item.optimize_standard" style="width: 100%" :header-cell-style="{ background: '#f0f2f5', color: '#333' }" :height="195">
            <el-table-column type="index" label="序号"> </el-table-column>
            <el-table-column prop="content" label="考核内容"> </el-table-column>
            <el-table-column prop="mark" label="评分标准"> </el-table-column>
            <el-table-column prop="percentage" label="分值占比(%)"> </el-table-column>
            <el-table-column prop="score" label="分值"> </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-link type="primary" :underline="false" style="margin-right: 12px" @click="onEdit(scope.row, 2, scope.$index, item)">编辑</el-link>
                <el-link type="danger" :underline="false" @click="onDel(scope.$index, item.optimize_standard)">删除</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="create-content" v-if="stepActive === 2">
      <div class="success-content">
        <i class="iconfont">&#xe730;</i>
        <p class="text">创建成功！</p>
      </div>
    </div>
    <div class="bottom-btn">
      <el-button @click="prevBtn()" class="btn-blue-line-all" v-if="stepActive === 1">上一步</el-button>
      <el-button @click="prevBtn()" class="btn-blue-line-all" v-if="stepActive === 2">返回</el-button>
      <el-button class="btn-blue" @click="nextBtn()" v-if="stepActive === 1" :disabled="analysisModule.length === 0">下一步</el-button>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="添加评分标准" :visible.sync="dialogVisible" width="30%" :before-close="cancelDialog" :close-on-click-modal="false">
      <el-form :model="socringData" :rules="socringRules" ref="socringData" label-width="120px" style="width: 600px">
        <el-form-item label="考核内容" prop="content">
          <el-input v-model.trim="socringData.content" clearable style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="评分标准" prop="mark">
          <el-input v-model.trim="socringData.mark" clearable style="width: 80%" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item label="分值占比" prop="percentage"> <el-input-number v-model="socringData.percentage" :min="1" :max="100" :precision="0" :controls="false" @change="testPercentage"></el-input-number>&ensp;% </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">取消</el-button>
        <el-button type="primary" @click="sureDialog">确定</el-button>
      </span>
    </el-dialog>
  </el-scrollbar>
</template>
<script>
import { getAnalysisListData, getDataTemplateDetailAPI, editDataTemplateAPI } from "@/utils/apis";

export default {
  name: "createTemplate",
  data() {
    return {
      stepActive: 1,
      loading: false,
      dialogVisible: false,
      editTemplateId: Number(this.$route.query.id) || "",
      templateData: {
        template_name: void 0,
        data_manage_id: void 0,
      },
      templateRules: {
        template_name: [
          { required: true, message: "数据模板名称不能为空", trigger: "blur" },
          { min: 2, max: 50, message: "模板名称长度为2-50个字符" },
        ],
        data_manage_id: [{ required: true, message: "数据分析选项不能为空", trigger: "change" }],
      },
      socringData: {
        content: void 0,
        mark: void 0,
        percentage: void 0,
        score: void 0,
      },
      socringRules: {
        content: [
          { required: true, message: "考核内容不能为空", trigger: "blur" },
          { min: 1, max: 40, message: "考核内容长度为1-40个字符" },
        ],
        mark: [
          { required: true, message: "评分标准不能为空", trigger: "blur" },
          { min: 1, max: 200, message: "评分标准长度为1-200个字符" },
        ],
        percentage: [{ required: true, message: "分值占比不符合要求", trigger: "blur" }],
      },
      dataAnalysisList: [],
      analysisModule: [],
      moduleData: [],
      analysisModuleList: [
        {
          label: "客户数据",
          options: [
            { value: 1, label: "人群画像" },
            { value: 2, label: "客户购买行为" },
            { value: 3, label: "店铺流量" },
            { value: 4, label: "行业数据" },
            { value: 5, label: "交易数据" },
          ],
        },
        {
          label: "客服数据",
          options: [
            { value: 6, label: "售前服务" },
            { value: 7, label: "售后服务" },
          ],
        },
      ],
      currentModule: void 0,
      currentType: void 0,
      isEdit: false,
      currentIndex: void 0,
      currentPercentage: void 0,
    };
  },
  methods: {
    selectAnalysisModule(val) {
      this.analysisModule = val;
      let selectArr = [];
      selectArr = val.map((o) => o.value);
      let oldMoudle = JSON.parse(JSON.stringify(this.moduleData));
      oldMoudle.every((o, i) => {
        if (!selectArr.includes(o.module_type)) oldMoudle.splice(i, 1);
      });
      this.moduleData = this.analysisModule.map((o) => ({ module_type: o.value, question: void 0, diagnose_score: void 0, diagnose_standard: [], optimize_score: void 0, optimize_standard: [] }));
      this.moduleData.forEach((e, i) => {
        oldMoudle.forEach((o, $i) => {
          if (e.module_type === o.module_type) this.moduleData.splice(i, 1, o);
        });
      });
      // console.log({ val, selectArr, oldMoudle });
    },
    addScore(item, type) {
      if (type === 1) {
        if (!item.diagnose_score) return this.$message.error("请先设置数据诊断分值！");
      } else {
        if (!item.optimize_score) return this.$message.error("请先设置优化建议分值！");
      }
      // if (this.percentageSum === 100) return this.$message.error("当前分值占比总和为100%！");
      this.dialogVisible = true;
      this.currentModule = item;
      this.currentType = type;
      this.isEdit = false;
      // console.log({ add: this.currentModule });
    },
    cancelDialog() {
      this.$refs["socringData"].clearValidate();
      this.socringData = {
        content: void 0,
        mark: void 0,
        percentage: void 0,
        score: void 0,
      };
      this.dialogVisible = false;
    },
    sureDialog() {
      this.$refs["socringData"].validate((valid) => {
        if (valid) {
          this.moduleData.map((o) => {
            if (o.module_type === this.currentModule.module_type) {
              if (this.isEdit) {
                this.currentType === 1 ? o.diagnose_standard.splice(this.currentIndex, 1, this.socringData) : o.optimize_standard.splice(this.currentIndex, 1, this.socringData);
              } else {
                // this.socringData.score = this.currentType === 1 ? (o.diagnose_score * this.socringData.percentage) / 100 : (o.optimize_score * this.socringData.percentage) / 100;
                this.currentType === 1 ? o.diagnose_standard.push(this.socringData) : o.optimize_standard.push(this.socringData);
              }
            }
          });
          this.$message.success("操作成功！");
          //   console.log({ moduleData: this.moduleData, socringData: this.socringData });
          this.currentModule = void 0;
          this.currentType = void 0;
          this.dialogVisible = false;
          this.socringData = {
            content: void 0,
            mark: void 0,
            percentage: void 0,
            score: void 0,
          };
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onEdit(item, type, index, parent) {
      // console.log({ parent });
      this.currentModule = parent;
      this.currentType = type;
      this.socringData = JSON.parse(JSON.stringify(item));
      this.currentPercentage = item.percentage;
      this.currentIndex = index;
      this.isEdit = true;
      this.testPercentage(this.socringData.percentage);
      this.dialogVisible = true;
    },
    onDel(index, parent) {
      parent.splice(index, 1);
    },
    prevBtn() {
      this.$router.push("/admin/dataTemplateManager");
    },
    nextBtn() {
      this.$refs["templateData"].validate((valid) => {
        if (valid) {
          this.moduleData.forEach((e) => {
            let cueWords = [
              { key: "question", value: `${this.typeReturnName(e.module_type)}题目不符合要求！` },
              { key: "diagnose_score", value: `${this.typeReturnName(e.module_type)}数据诊断分值不符合要求！` },
              { key: "optimize_score", value: `${this.typeReturnName(e.module_type)}优化建议分值不符合要求！` },
              { key: "diagnose_standard", value: `${this.typeReturnName(e.module_type)}数据诊断评分标准不能为空！` },
              { key: "optimize_score", value: `${this.typeReturnName(e.module_type)}优化建议评分标准不能为空！` },
            ];
            cueWords.map((o) => {
              if (!e[o.key]) {
                this.$message.error(o.value);
                throw new Error(o.value);
              }
            });
          });
          let params = { template_name: this.templateData.template_name, data_manage_id: this.templateData.data_manage_id, module_data: JSON.parse(JSON.stringify(this.moduleData)) };
          params.module_data.forEach((e) => {
            e.diagnose_standard = JSON.stringify(e.diagnose_standard);
            e.optimize_standard = JSON.stringify(e.optimize_standard);
          });
          if (!!this.editTemplateId) params = { ...params, ...{ data_template_id: this.editTemplateId } };
          editDataTemplateAPI(params)
            .then((res) => {
              if (res.code === 200) {
                this.stepActive = 2;
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getAnalysisList() {
      getAnalysisListData({ paging: 0 })
        .then((res) => {
          if (res.code === 200) {
            this.dataAnalysisList = res.data.list;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    getEditData() {
      getDataTemplateDetailAPI({ data_template_id: this.editTemplateId })
        .then((res) => {
          if (res.code === 200) {
            this.templateData.template_name = res.data.template_name;
            this.templateData.data_manage_id = res.data.data_manage_id;
            res.data.module_data.forEach((e) => {
              e.question = e.data.question;
              e.diagnose_score = e.data.diagnose_score;
              e.optimize_score = e.data.optimize_score;
              e.diagnose_standard = JSON.parse(e.data.diagnose_standard);
              e.optimize_standard = JSON.parse(e.data.optimize_standard);
              this.analysisModule.push({ value: e.module_type });
              if (!!e.question && !!e.diagnose_score && !!e.optimize_score && !!e.diagnose_standard && !!e.optimize_standard) delete e.data;
            });
            this.moduleData = res.data.module_data;
            // console.log(this.moduleData);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    typeReturnName(type) {
      return type === 1 ? "人群画像" : type === 2 ? "客户购买行为" : type === 3 ? "店铺流量" : type === 4 ? "行业数据" : type === 5 ? "交易数据" : type === 6 ? "售前服务" : "售后服务";
    },
    testPercentage(val) {
      // console.log({ edit: this.currentModule });
      let score = this.currentType === 1 ? (this.currentModule.diagnose_score * val) / 100 : (this.currentModule.optimize_score * val) / 100;
      if (score < 0.5) {
        this.$nextTick(() => (this.socringData.percentage = void 0));
        return this.$message.error("计算出的分值不能小于0.5分");
      } else {
        let newSum = this.isEdit ? this.percentageSum - this.currentPercentage + val : this.percentageSum + val;
        // console.log({ newSum, percentageSum: this.percentageSum });
        if (newSum > 100) {
          this.$nextTick(() => (this.socringData.percentage = void 0));
          return this.$message.error("分值占比之和不能大于100");
        } else if (newSum === 100) {
          let typeParam = this.currentType === 1 ? "diagnose_" : "optimize_";
          let standardArr = JSON.parse(JSON.stringify(this.currentModule[`${typeParam}standard`]));
          this.isEdit ? standardArr.splice(this.currentIndex, 1) : standardArr;
          let sum = standardArr.reduce((total, val) => {
            return total + val.score;
          }, 0);
          // console.log(this.isEdit, sum);
          this.socringData.score = this.scoreRules(this.currentModule[`${typeParam}score`] - sum);
        } else {
          this.socringData.score = this.scoreRules(score);
        }
      }
      // console.log({ val, score: this.socringData.score });
    },
    scoreRules(num) {
      // 小数部分四舍五入规则：0.01-0.24=0分、0.25-0.74=0.5分、0.75-0.99=1分；当算出的分值小于0.5，提示修改占比；最后一个分值：总分值-（除最后一个分值的和）
      let mantissa = 0;
      let number = 0;
      if (num % 1 >= 0.01 && num % 1 <= 0.24) {
        mantissa = 0;
      } else if (num % 1 >= 0.25 && num % 1 <= 0.74) {
        mantissa = 0.5;
      } else if (num % 1 >= 0.75 && num % 1 <= 0.99) {
        mantissa = 1;
      }
      number = Math.floor(num) + mantissa;
      return number;
    },
    changeTotalScore(item, type) {
      let itemArr = item[`${type}_standard`];
      let totalScore = item[`${type}_score`];
      let percentageSum = itemArr.reduce((total, val) => {
        return total + val.percentage;
      }, 0);
      if (percentageSum === 100) {
        let sum = 0;
        itemArr.map((item, i) => {
          if (i === itemArr.length - 1) {
            itemArr[itemArr.length - 1].score = totalScore - sum;
          } else {
            item.score = this.scoreRules((totalScore * item.percentage) / 100);
            sum += item.score;
          }
        });
      } else {
        itemArr.map((item, i) => {
          item.score = this.scoreRules((totalScore * item.percentage) / 100);
        });
      }
      // console.log({ item, type, percentageSum });
    },
  },
  mounted() {
    this.getAnalysisList();
    if (this.editTemplateId) this.getEditData();
  },
  computed: {
    percentageSum() {
      let typeParam = this.currentType === 1 ? "diagnose_standard" : "optimize_standard";
      let sum = 0;
      this.currentModule[typeParam].map((o) => {
        sum += o.percentage;
      });
      return sum;
    },
  },
};
</script>

<style lang="scss" scoped>
.template-wrapper {
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 60px;
    }
  }
}
.main-title-text {
  font-size: 19px;
  font-weight: 800;
  color: #606266;
}
.create-content {
  margin: 60px 0;

  .success-content {
    margin-top: 155px;
    text-align: center;
    display: flex;
    justify-content: center;
    .iconfont {
      color: #1fd18c;
      font-size: 52px;
    }
    .text {
      font-size: 24px;
      margin: 20px 0 0 17px;
      font-weight: bold;
    }
  }
}
.bottom-btn {
  text-align: center;
}
.module-data {
  margin: 24px 0;
  ::v-deep .el-form {
    width: 800px;
  }
}
</style>
